<template>
  <div>
    <div v-show="$store.state.common.sIsLoading">Loading...</div>
    <div v-show="!$store.state.common.sIsLoading">
      <div
        class="uk-container"
        style="
          width: 100%;
          padding: 5px;
          box-sizing: border-box;
          position: relative;
          background-color: #fffff0;
        "
      >
        <!-- 実施中 -->
        <div
          style="position:absolute:top; display:table; float:left; width:33%;"
        >
          <div
            style="
              padding: 10px;
              box-sizing: border-box;
              display: inline-block;
              width: 100%;
            "
          >
            <div style="width: 100%; text-align: center; margin-bottom: 10px">
              <div
                style="
                  display: inline-block;
                  width: 100%;
                  font-size: 1.3em;
                  background-color: #ffffff;
                  border-radius: 10px;
                  padding: 5px;
                  box-sizing: border-box;
                  border: double 5px #000000;
                  color: #696969;
                "
              >
                <b>{{ $store.state.intelligent_home.sLblOnGoing }}</b>
              </div>
            </div>
          </div>
          <div
            style="
              padding: 10px;
              box-sizing: border-box;
              display: inline-block;
              width: 100%;
              height: 300px;
              overflow: scroll;
            "
          >
            <div
              v-for="(dt, idx) in vScOnGoingListData"
              :key="dt.sc_id"
              class="sc-matter"
              style="
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
                border: solid 3px #dcdcdc;
                border-radius: 10px;
                background-color: #ffffff;
                margin-bottom: 10px;
              "
              @click="doOpenMatterDetail(dt.sc_id)"
            >
              <div
                style="
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                  font-size: 1em;
                  color: #696969;
                "
              >
                <div
                  style="
                    display: inline-block;
                    width: 50%;
                    text-align: left;
                    color: #696969;
                  "
                >
                  <small>{{ idx + 1 }}</small>
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 50%;
                    text-align: right;
                    color: #696969;
                  "
                >
                  <small
                    ><span
                      style="
                        background-color: #696969;
                        color: #ffffff;
                        border-radius: 20px;
                        padding: 5px;
                        box-sizing: border-box;
                      "
                      ><b>残り{{ dt.deadline }}日</b></span
                    ></small
                  >
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    font-size: 1em;
                    color: #696969;
                  "
                >
                  <b>{{ dt.com_name }}</b>
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    color: #696969;
                    padding-left: 5px;
                    box-sizing: border-box;
                  "
                >
                  <small>{{
                    $store.state.intelligent_home.sLblScAnswerPeriod
                  }}</small>
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    color: #696969;
                  "
                >
                  <small
                    >{{ dt.sc_answer_start_date_name }} ～
                    {{ dt.sc_answer_end_date_name }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 次の処理 -->
        <div
          style="position:absolute:top; display:table; float: left; width:33%;"
        >
          <div
            style="
              padding: 10px;
              box-sizing: border-box;
              display: inline-block;
              width: 100%;
            "
          >
            <div style="width: 100%; text-align: center; margin-bottom: 10px">
              <div
                style="
                  display: inline-block;
                  width: 100%;
                  font-size: 1.3em;
                  background-color: #ffffff;
                  border-radius: 10px;
                  padding: 5px;
                  box-sizing: border-box;
                  border: double 5px #000000;
                  color: #696969;
                "
              >
                <b>{{ $store.state.intelligent_home.sLblToDo }}</b>
              </div>
            </div>
          </div>
          <div
            style="
              padding: 10px;
              box-sizing: border-box;
              display: inline-block;
              width: 100%;
              height: 300px;
              overflow: scroll;
            "
          >
            <div
              v-for="(dt, idx) in vScToDoListData"
              :key="dt.sc_id"
              class="sc-matter"
              style="
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
                border: solid 3px #dcdcdc;
                border-radius: 10px;
                background-color: #ffffff;
                margin-bottom: 10px;
              "
              @click="doOpenMatterDetail(dt.sc_id, dt.sc_status)"
            >
              <div
                style="
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                  font-size: 1em;
                  color: #696969;
                "
              >
                <div
                  style="
                    display: inline-block;
                    width: 50%;
                    text-align: left;
                    color: #696969;
                  "
                >
                  <small>{{ idx + 1 }}</small>
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 50%;
                    text-align: right;
                    color: #696969;
                  "
                >
                  <small
                    ><span
                      style="
                        background-color: #696969;
                        color: #ffffff;
                        border-radius: 20px;
                        padding: 5px;
                        box-sizing: border-box;
                      "
                      ><b>{{ dt.sc_status_name }}</b></span
                    ></small
                  >
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    font-size: 1em;
                    color: #696969;
                  "
                >
                  <b>{{ dt.com_name }}</b>
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    color: #696969;
                    padding-left: 5px;
                    box-sizing: border-box;
                  "
                >
                  <small>{{
                    $store.state.intelligent_home.sLblScAnswerPeriod
                  }}</small>
                </div>
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    color: #696969;
                  "
                >
                  <small
                    >{{ dt.sc_answer_start_date_name }} ～
                    {{ dt.sc_answer_end_date_name }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 受付中 -->
        <div
          style="position:absolute:top; display:table; float: left; width:33%;"
        >
          <div
            style="
              padding: 10px;
              box-sizing: border-box;
              display: inline-block;
              width: 100%;
            "
          >
            <div style="width: 100%; text-align: center; margin-bottom: 10px">
              <div
                style="
                  display: inline-block;
                  width: 100%;
                  font-size: 1.3em;
                  background-color: #ffffff;
                  border-radius: 10px;
                  padding: 5px;
                  box-sizing: border-box;
                  border: double 5px #000000;
                  color: #696969;
                "
              >
                <b>{{ $store.state.intelligent_home.sLblPreparation }}</b>
              </div>
            </div>
          </div>
          <div
            style="
              padding: 10px;
              box-sizing: border-box;
              display: inline-block;
              width: 100%;
              height: 300px;
              overflow: scroll;
            "
          >
            <div
              v-for="(dt, idx) in vScPreparationListData"
              :key="dt.sc_id"
              class="sc-matter"
              style="
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
                border: solid 3px #dcdcdc;
                border-radius: 10px;
                background-color: #ffffff;
                margin-bottom: 10px;
              "
              @click="doOpenMatterDetail(dt.sc_id)"
            >
              <div
                style="
                  display: inline-block;
                  width: 100%;
                  text-align: center;
                  font-size: 1em;
                  color: #696969;
                "
              >
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    color: #696969;
                  "
                >
                  <small>{{ idx + 1 }}</small>
                </div>
                <!-- <div style="display:inline-block; width:50%; text-align:right; color:#696969;">
                  <small><span style="background-color:#6495ed; color:#ffffff; border-radius:20px; padding:5px; box-sizing:border-box;"><b>残り{{ dt.deadline }}日</b></span></small>
                </div> -->
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    font-size: 1em;
                    color: #696969;
                  "
                >
                  <b>{{ dt.com_name }}</b>
                </div>
                <!-- <div style="display:inline-block; width:100%; text-align:left; color:#696969; padding-left:5px; box-sizing:border-box;">
                  <small>アンケート期間</small>
                </div> -->
                <div
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    color: #696969;
                  "
                >
                  <small
                    >{{ dt.sc_answer_start_date_name }} ～
                    {{ dt.sc_answer_end_date_name }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div style="padding:10px 50px;">
    <h3 style="text-align:left; color:#696969;">{{ $store.state.common.sScreenTitle }}</h3>
    <hr/>
  </div> -->

  <!-- <p><b>{{ vMessage }}</b></p> -->
</template>

<script>
import constant from "@/constant.js";

export default {
  data() {
    return {
      vComId: this.$store.state.common.sComId,
      vListData: [], // 実施管理データ情報
      vScPreparationListData: [], // 準備中リスト
      vScOnGoingListData: [], // 実施中リスト
      vScToDoListData: [], // 次の処理リスト
      vScId: "",

      // vUpdateMode: 0,
      // vSelectedRow: false,
      // vModalId: 'modal-confirm-customer-workplace',
      vMessage: "",
    };
  },
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    // ログイン状態判定
    this.doAuthLoginInfo();
    if (!this.$store.state.common.sIsLogined) {
      this.$router.push("/login");
    }
    // 画面タイトル
    this.$store.commit("common/doSetScreenTitle", {
      vScreenTitle: "インテリジェント/ホーム",
    });
    // DB参照処理
    if (this.vComId) {
      this.vMessage = "";
      this.$axios
        .get(constant.cServerRoute.intelligent.home)
        .then(
          function (response) {
            console.log(response);
            // 実施管理データ参照処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              // 実施管理データを保持する
              if (response.data.return_data) {
                this.vListData = response.data.return_data;
                this.doCreateScListData();
              }
            } else {
              this.vMessage = response.data.return_message;
            }
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
          this.vMessage = error;
        });
    }
  },
  // 画面読み込み終わった？
  mounted() {
    this.$store.state.common.sIsLoading = false;
  },
  methods: {
    // 実施管理データを整形する
    doCreateScListData() {
      // 準備中用
      this.vScPreparationListData = this.vListData.filter(
        (a) =>
          a.sc_status == this.$store.state.intelligent_home.sScStatus.requested
      );
      this.vScPreparationListData.sort(
        (a, b) =>
          new Date(a.sc_answer_start_date) - new Date(b.sc_answer_start_date)
      );
      // 実施中用
      this.vScOnGoingListData = this.vListData.filter(
        (a) =>
          a.sc_status == this.$store.state.intelligent_home.sScStatus.ongoing
      );
      this.vScOnGoingListData.sort(
        (a, b) =>
          new Date(a.sc_answer_end_date) - new Date(b.sc_answer_end_date)
      );
      // 次の処理用
      this.vScToDoListData = this.vListData.filter(
        (a) =>
          a.sc_status !=
            this.$store.state.intelligent_home.sScStatus.requested &&
          a.sc_status != this.$store.state.intelligent_home.sScStatus.ongoing
      );
    },
    // 案件詳細表示
    doOpenMatterDetail(scId, scStatus = -1) {
      if (this.$store.state.common.sServerProcessing) {
        return;
      }
      // ボタン制御
      this.$store.commit("common/doStartServerProcess");
      console.log("sc_id = ", scId);
      this.vScId = scId;
      this.$store.state.sc_matter.sScId = scId;
      // 実施状態判定
      if (scStatus > 0) {
        switch (scStatus) {
          // OCR済みファイル取込
          case this.$store.state.intelligent_home.sScStatus.inocr:
            console.log("OCR処理");
            this.$router.push("/intelligent-ocr-result-capture");
            break;
          case this.$store.state.intelligent_home.sScStatus.inanalysis:
            console.log("厚労省ツール実行");
            this.$router.push("/intelligent-analysis");
            break;
          default:
            console.log("その他");
            break;
        }
      }

      // ボタン制御
      this.$store.commit("common/doEndServerProcess");
    },
    // ID,パスワード照合
    doAuthLoginInfo() {
      if (sessionStorage.getItem("storageId")) {
        let createHash = require("sha256-uint8array").createHash;
        let tmp = createHash()
          .update(sessionStorage.getItem("storagePw"))
          .digest("hex");
        this.$axios
          .post(constant.cServerRoute.common.login, {
            pLoginId: sessionStorage.getItem("storageId"),
            pLoginPw: tmp,
          })
          .then(
            function (response) {
              console.log(response);
              if (
                response.data.return_result !=
                constant.cServerReturnResult.success
              ) {
                this.$store.state.common.sIsLogined = false;
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          });
      }
    },
  },
};
</script>
